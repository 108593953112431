import { queryOptions } from 'mocks/responses/fixtures/queryOptions';

/* eslint-disable import/no-anonymous-default-export */
export default {
  sidebar: {
    dashboard: 'Dashboard',
    systemHealth: 'System Health',
    data: 'Data',
    jobs: 'Jobs',
    scale: 'Scale',
    query: 'Query',
    security: 'Security',
    support: 'Support',
    docs: 'Docs',
    pageDocs: {
      alter: 'Alter Jobs',
      autoBatch: 'AutoBatch',
      batch: 'Batch Jobs',
      dictionary: 'Dictionary',
      file: 'File',
      function: 'Function',
      kafka: 'Kafka',
      kinesis: 'Kinesis',
      query: 'Query',
      scale: 'Scale',
      source: 'Source',
      summary: 'Summary',
      table: 'Table',
      transform: 'Transform',
      user: 'User',
      role: 'Roles',
      systemHealth: 'System Health',
      summarySettings: 'Summary Settings',
    },
  },
  auth: {
    login: {
      email: 'Email',
      password: 'Password',
      errors: {
        emailRequired: 'Email is required',
        emailFormat: 'Wrong email format',
        passwordRequired: 'Password is required',
      },
      BTN: 'Sign In',
      forgotPass: 'Forgot password?',
      loginSsoBtn: (name: string) => `Sign In with ${name}`,
      dividerText: 'or',
    },
    resetPass: {
      text: 'Please, enter an email associated with your account and we will send you instructions to reset password.',
      email: 'Email',
      BTN: 'Send instructions',
      linkToSignIn: {
        text: 'Remember your password? ',
        link: 'Sign In',
      },
      errors: {
        emailRequired: 'Email is required',
        emailFormat: 'Wrong email format',
      },
      success: {
        title: 'Recovery Email Sent',
        text: 'We have sent an email with a password reset link to ',
        BTN: 'Return to login',
      },
    },
    changePass: {
      newPass: 'New password',
      repeatPass: 'Repeat password',
      BTN: 'Change password',
      errors: {
        newPassRequired: 'New password is required',
        repeatPassRequired: 'Repeat password is required',
        notMatch: 'Passwords do not match',
      },
      linkToSignIn: {
        text: 'Remember your password? ',
        link: 'Sign In',
      },
      success: {
        title: 'Password Reset',
        text: 'You have successfully updated your password!',
        BTN: 'Return to login',
      },
    },
  },
  profile: {
    changePass: {
      tabLabel: 'Change password',
      oldPass: 'Current Password',
      newPass: 'New Password',
      repeatPass: 'Confirm New Password',
      updateBTN: 'Update',
      errors: {
        oldPassRequired: 'You must enter the current password',
        newPassRequired: 'New password is required',
        repeatPassRequired: 'Repeat password is required',
        notMatch: 'Passwords do not match',
      },
    },
    settings: {
      tabLabel: 'Personal settings',
      language: 'Language',
      timezone: 'Timezone',
      timezoneText: 'The selected timezone will only work on the current device',
      updateBTN: 'Update',
      timezoneSuccessMsg: 'Timezone successfully changed',
      languageOptions: {
        en: 'English',
      },
      timezoneOptions: {
        local: 'Local',
        utc: 'UTC',
      },
    },
  },

  org: {
    queryOptions: {
      success: 'Org query options successfully changed',
    },
  },

  security: {
    tabs: {
      users: 'Users',
      roles: 'Roles',
      auditTrails: 'Audit Trail',
      buckets: 'Buckets',
      credentials: 'Credentials',
      pendingInvites: 'Pending Invites',
      authLogs: 'Auth Logs',
    },
    authLogs: {
      table: {
        time: 'time',
        timestampUtc: 'timestamp UTC',
        username: 'username',
        type: 'type',
        realmId: 'realmId',
        clientId: 'clientId',
        ipAddress: 'ipAddress',
        error: 'error',
      },
      fields: {
        labels: {
          timerange: 'Timerange',
          user: 'User',
          type: 'Type',
          limit: 'Limit',
        },
        required: {
          fromDate: 'From date is required',
          toDate: 'To date is required',
          limit: 'Limit is required',
        },
      },
      sidebar: {
        view: {
          title: 'View auth log',
          time: 'Time',
          timestampUtc: 'Timestamp UTC',
          type: 'Type',
          realmId: 'RealmId',
          clientId: 'ClientId',
          userId: 'UserId',
          sessionId: 'SessionId',
          ipAddress: 'ipAddress',
          details: 'Details',
        },
      },
    },
    auditTrail: {
      table: {
        created: 'created',
        user: 'user',
        action: 'action',
        text: 'text',
      },
      form: {
        labels: {
          org: 'Organization',
          project: 'Project',
          table: 'Table',
        },
        submitBtn: 'Retrieve Logs',
        downloadJsonBtn: 'Download JSON',
      },
    },
    user: {
      invite: {
        title: 'Invite new users',
        roleLabel: 'Select role',
        roleAdmin: 'Org admin',
        roleOperator: 'Operator',
        rolePublisher: 'Publisher',
        roleReader: 'Reader',
        emailLabel: 'Email',
        scopeLabel: 'Scope',
        resourceLabel: (resType: string) => `Select ${resType}`,
        addBTN: 'Add user',
        addFieldsBTN: 'Add additional user',
        inviteSent: 'Invitation email sent!',
        errors: {
          emailRequired: 'Email is required',
          emailFormat: 'Wrong email format',
          roleRequired: 'Role is required',
          scopeRequired: 'Scope is required',
          resourceRequired: (resType: string) => `${resType} is required`,
        },
      },
    },
    users: {
      title: 'Users',
      user: 'User',
      role: 'Role',
      scope: 'Scope',
      permissions: 'Permissions',
      tooltipUserDeSync:
        'User audit has been flagged. This could indicate that the user object is out of sync. Contact support.',
      edit: {
        title: (name: string) => `Edit ${name}`,
        roleLabel: 'Select role',
        emailLabel: 'Email',
        submitBTN: 'Save changes',
        addRoleBTN: 'Add role',
        deleteBTN: 'Delete user',
        deleteRoleBTN: 'Delete',
        success: 'User successfully modified',
      },
      delete: {
        title: (name: string) => `Delete ${name}`,
        content: 'Are you sure you want to delete ',
        text: (name: string) => `Are you sure you want to delete ${name}`,
        cancelBTN: 'Cancel',
        submitBTN: 'Delete',
        success: 'User successfully deleted',
        successRole: 'Role successfully deleted',
      },
      invites: {
        successResend: 'Invitation sent successfully',
        successDeleted: 'Invitation successfully deleted',
        title: (name: string) => `Delete the invite for ${name}`,
        content: 'Are you sure you want to delete the invite for ',
      },
    },
    roles: {
      role: 'Roles',
      editRole: 'Edit Role',
      newRole: 'New custom role',
      addNewEntitlement: 'Add New Policy',
      currentEntitlements: 'Current Policies',
      addPolicy: 'Add Policy',
      editPolicy: 'Edit Policy',
      infoBox: 'Updates are not published until role is saved',
      form: {
        nameLabel: 'Role Name',
        descLabel: 'Description',
        scopeLabel: 'Scope Level',
        projectLabel: 'Scope',
        permissionsLabel: 'Permissions',
        saveBtn: 'Save changes',
        createBtn: 'Create Role',
        deleteBtn: 'Delete role',
        deleteEntitlementBtn: 'Delete Policy',
        successRoleUpdated: 'Role successfully updated',
        successRoleCreate: 'Role successfully created',
        addAllPermissions: 'Add All',
        listAllPermissions: 'List All',
      },
      err: {
        nameRequired: 'Role name is required',
        scopeLevelRequired: 'Scope Level field is required',
        scopeRequired: 'Scope field is required',
        permissionsRequired: 'Permissions field is required',
      },
      table: {
        name: 'Current Policies',
      },
    },
    buckets: {
      newTitle: 'New bucket',
      editTitle: 'Edit bucket',
      form: {
        labels: {
          storageId: 'Storage ID',
          name: 'Name',
          description: 'Description',
          bucketName: 'Bucket name',
          cloud: 'Select cloud provider',
          region: 'Region',
          credentialId: 'Credential (optional)',
          bucketPath: 'Bucket path',
          endpoint: 'Endpoint',
        },
        rules: {
          name: 'Name is required',
          bucketName: 'Bucket name is required',
          cloud: 'Cloud is required',
        },
        buttons: {
          create: 'Create bucket',
          edit: 'Publish update',
          delete: 'Delete bucket',
          copyStorageId: 'Copy Storage ID',
        },
      },
      table: {
        name: 'Name',
        bucketName: 'Bucket Name',
        cloud: 'Cloud',
        region: 'Region',
      },
      notifications: {
        delete: 'Bucket successfully deleted',
        created: 'Bucket successfully created',
        updated: 'Bucket successfully updated',
      },
    },
    credentials: {
      newTitle: 'New credential',
      editTitle: 'Edit credential',
      form: {
        labels: {
          credential_id: 'Credential ID',
          name: 'Name',
          description: 'Description',
          types: 'Cloud Provider Type',
          hdxKey: 'HDX credential key',
          file: 'Upload Credential JSON (optional)',
        },
        rules: {
          name: 'Name is required',
          type: 'Type is required',
          typeField: (field: string) => `${field} is required`,
        },
        buttons: {
          create: 'Create credential',
          delete: 'Delete credential',
          copyHdxKey: 'Copy hdx credential key',
          copyCredentialIDKey: 'Copy credential ID',
        },
      },
      table: {
        name: 'Name',
        credentialName: 'Credential Name',
        type: 'Type',
      },
      notifications: {
        delete: 'Credential successfully deleted',
        created: 'Credential successfully created',
      },
    },
  },

  addNewSidebar: {
    addNewButtonText: 'Add new',
    title: 'Add new',
    alter: 'Alter job',
    alterSub: 'Modify append-only data',
    summary: 'Summary Table',
    summarySub: 'Aggregate table',
    view: 'Table View',
    viewSub: 'Limit user dataset access to specified columns',
    batch: 'Batch job',
    batchSub: 'Bulk data ingest',
    function: 'Data Function',
    functionSub: 'Custom SQL function',
    dictionary: 'Data dictionary',
    dictionarySub: 'Custom lookup table',
    ingest: 'Table Source',
    ingestSub: 'Methods for data ingestion',
    transform: 'Table Transform',
    transformSub: 'Schema for transforming and enriching incoming data',
    pool: 'Resource pool',
    poolSub: 'Workload isolation resources',
    file: 'Data File',
    fileSub: 'Source data for custom dictionary',
    table: 'Table',
    tableSub: 'Namespace definition',
    user: 'User',
    userSub: 'Invite a friend',
    bucket: 'Bucket',
    bucketSub: 'Secondary storage',
    role: 'Role',
    roleSub: 'Create Custom Role',
    credential: 'Credential',
    credentialSub: 'Add new credentials',
  },

  headerDropMenu: {
    settings: 'Settings',
    token: 'Create token',
    logOut: 'Log Out',
  },

  dashboard: {
    chart: {
      titleEvent: 'Events per Second',
      titleQuery: 'Queries per Second',
    },
  },

  data: {
    project: {
      projectSettings: 'Project settings',
      modal: {
        title: 'Delete project',
        content: (projName: string) =>
          `Deleting project ${projName}. All resources contained in the the project will be deleted. Type “DELETE” to confirm.`,
        success: 'Project successfully deleted',
      },
      renameSidebar: {
        title: 'Project - rename or remove',
        projectNameLabel: 'Project name',
        success: 'Project successfully renamed',
        BTN: {
          delete: 'Delete project',
          submit: 'Save changes',
        },
      },
      queryOptions: {
        success: 'Project query options successfully changed',
      },
      rateLimit: {
        title: 'Rate Limit Settings',
        limitLabel: 'Limit',
        burstLabel: 'Burst',
        submitBtn: 'Save changes',
        rightText: 'Bytes',
        successMsg: 'Rate Limit settings successfully changed',
        validateErrors: {
          limit: 'Limit value must be greater than 0',
          burst: 'Burst value must be greater than 0',
          required: 'This field is required',
        },
      },
    },
    function: {
      new: {
        title: 'New custom function',
        projectLabel: 'Select project',
        nameLabel: 'Function name',
        descLabel: 'Description',
        expLabel: 'SQL expression',
        submitBTN: 'Publish function',
        success: 'Custom function successfully added',
        tooltips: {
          expLabel: `The sql section of the function config corresponds to the expression which immediately follows: CREATE FUNCTION &lt;function name&gt; AS ...`,
        },
        err: {
          projId: {
            required: 'Please, select the project',
          },
          name: {
            required: 'Name is required',
            max: 'Name must be less than 64 symbols',
          },
          exp: {
            required: 'SQL expression is required',
          },
        },
      },
      edit: {
        title: 'Edit custom function',
        success: 'Custom function successfully modified',
        submitBTN: 'Save changes',
        deleteBTN: 'Delete function',
      },
      delete: {
        title: 'Delete custom function',
        text: (name: string) => `Are you sure you want to delete the function ${name}?`,
        success: 'Custom function successfully deleted',
      },
    },
    dictionary: {
      new: {
        title: 'New data dictionary',
        projectLabel: 'Select project',
        nameLabel: 'Dictionary name',
        descLabel: 'Description',
        schemaLabel: 'Schema definition',
        keyLabel: 'Primary key',
        lifetimeLabel: 'Lifetime seconds',
        dictionaryLoad: 'Dictionary load level',
        formatLabel: 'Format',
        layoutLabel: 'Layout',
        filenameLabel: 'Select file',
        newFileName: 'Filename',
        submitBTN: 'Create dictionary',
        submitTransform: 'Generate schema',
        success: 'Dictionary successfully added',
        tooltips: {
          filenameLabel: 'Name of the dictionary file',
          schemaLabel:
            'Array of columns similar to Transforms expect timestamp is not required and primary column is not specified',
          keyLabel:
            'The column/s that should be used for the primary key (note primary should not be used in the output_columns definition)',
          formatLabel: 'The format for the file being used as a dictionary',
          layoutLabel: 'Way to store dictionary in memory',
          lifetimeLabel:
            'How often ClickHouse refreshes dictionary from local file. Recommended set to 5',
        },
        type: {
          title: 'File type',
          tabs: {
            csv: 'CSV',
            other: 'OTHER',
            json: 'JSON',
          },
        },
        method: {
          title: 'Method',
          tabs: {
            generate: {
              title: 'Generate',
            },
            manual: {
              title: 'Manual',
            },
          },
        },
        err: {
          projId: {
            required: 'Please, select the project',
          },
          name: {
            required: 'Name is required',
            max: 'Name must be less than 64 symbols',
          },
          key: {
            required: 'Primary key is required',
            format: 'Primary key must be in JSON format',
          },
          schema: {
            required: 'Schema definition is required',
            format: 'Schema definition must be in JSON format',
          },
          format: {
            required: 'Please, select the format',
          },
          layout: {
            required: 'Please, select the layout',
          },
          selectFile: {
            required: 'Please, select the file',
          },
          filename: {
            required: 'Filename is required',
          },
          file: {
            required: 'Please, upload a file',
          },
        },
      },
      edit: {
        title: 'Edit data dictionary',
        deleteBTN: 'Delete dictionary',
        success: 'Dictionary successfully modified',
        submitBTN: 'Save changes',
      },
      delete: {
        title: 'Delete data dictionary',
        text: (name: string) => `Are you sure you want to delete the dictionary ${name}?`,
        success: 'Dictionary successfully deleted',
      },
    },
    files: {
      titleNew: 'New file',
      titleEdit: 'Update file',
      projectLabel: 'Select project',
      nameLabel: 'File name',
      fileLabel: 'Upload file',
      submitBTN: 'Upload file',
      editBTN: 'Save changes',
      successNew: 'File successfully uploaded',
      successEdit: 'File successfully modified',
      delete: {
        title: 'Delete file',
        text: (qty?: number) =>
          `Are you sure you want to delete the file? ${
            qty
              ? qty > 1
                ? qty + ' dictionaries use this file'
                : qty + ' dictionary use this file'
              : ''
          }`,
        success: 'File successfully deleted',
      },
      replace: {
        title: 'Replace file',
        text: (qty?: number) =>
          `Are you sure you want to replace the file? ${
            qty
              ? qty > 1
                ? qty + ' dictionaries use this file'
                : qty + ' dictionary use this file'
              : ''
          }`,
        success: 'File successfully replaced',
      },
      err: {
        projectRequired: 'You must select project',
      },
    },
    orgSettings: {
      title: 'Organization Settings',
    },
    table: {
      list: {
        expandTitle: 'Expand all',
        collapseTitle: 'Collapse all',
      },
      new: {
        title: 'New table',
        summaryTitle: 'New summary table',
        parentTableTitle: 'Parent table columns',
        projectLabel: 'Select project',
        nameLabel: 'Table name',
        projectNameLabel: 'Project name',
        parentTableLabel: 'Select parent table',
        descLabel: 'Description',
        submitBTN: 'Create table',
        advancedBTN: 'Edit advanced options',
        success: 'Table successfully created',
        parentTableHeaders: {
          column: 'column',
          cardinality: 'cardinality',
        },
        err: {
          projId: {
            required: 'Please, select the project',
          },
          name: {
            required: 'Name is required',
            max: 'Name must be less than 64 symbols',
          },
          projectName: {
            required: 'Project name is required',
            max: 'Project name must be less than 64 symbols',
          },
          summarySql: {
            required: 'Sql is required',
          },
          parentTable: {
            required: 'Parent table is required',
          },
        },
        advanced: {
          title: 'Table - advanced options',
          mergeTitle: 'Auto-merge Options',
          mergeLabel: 'Disable merge',
          lifecycleTitle: 'Lifecycle Options',
          lifecycleLabel: 'Max Age',
          lifecycleRight: 'Days',
          expectedTBRight: 'TB',
          indexTitle: 'Index Options',
          sortKeysLabel: 'Sort keys',
          shardKeyLabel: 'Shard key',
          summaryTitle: 'Summary Tables Only',
          summaryLabel: 'Enable summary',
          summarySqlLabel: 'Summary sql',
          submitBTN: 'Save changes',
          indexOptionsWarning:
            'Incorrectly sorting data can cause performance degradation. Shard Keys can cause high levels of stored data fragmentation and should be used with care. It is suggested that you contact Hydrolix support if you are considering using these functions.',
          sortKeyDocs: 'Sort Key Docs',
          shardKeyDocs: 'Shard Key Docs',
          err: {
            maxAge: 'Max age must be an integer',
            sortKeys: 'Sort keys must be in JSON format',
          },
        },
      },
      summary: {
        editTitle: 'Edit summary settings',
        editButton: 'Save settings',
        tabs: {
          new: 'Summary Form',
          analysis: 'Column Analysis',
          summarySettings: 'Summary Settings',
        },
        columnAnalysis: {
          titles: {
            columnTable: 'Columns to Analyze',
            timestampForm: 'Timestamp Rollup',
            timeframeOptionsForm: 'Recent Data Range',
            results: 'Results',
          },
          fields: {
            parentTable: 'Select parent table',
            timestamp: 'Timestamp column',
            timestampValue: 'Value',
            timestampInterval: 'Interval',
            timeframeValue: 'Value',
            timeframeInterval: 'Interval',
          },
          tables: {
            columnName: 'column_name',
            uniqValues: 'uniq_values',
            percentile: 'percentile_%',
            rating: 'impact',
            totalSummaryRows: 'total_summary_rows',
            totalRawRows: 'total_raw_rows',
            percentReduction: 'percent_reduction',
          },
          rules: {
            columnNames: 'At least one column should be selected',
            timestamp: 'Timestamp value is required',
            timestampValue: 'Timestamp value must be greater than 0',
            timeframeValue: 'Timeframe value must be greater than 0',
          },
          btns: {
            submit: 'Analyze Columns',
            copySql: 'Copy sql generated',
            copySqlTooltip: 'Copied sql generated',
            copyColumnsTooltip: 'Copied columns to form',
            copyColumns: 'Copy Columns To Form',
          },
          errors: {
            timestampColumn:
              'Illegal Aggregation. Column <timestamp_column_name> cannot be aggregated if used in WHERE clause.',
          },
        },
      },
      health: {
        title: 'Table health',
        titleSummary: 'Summary table health',
        na: 'n/a',
        latency: 'Ingest Latency',
        download: 'Download JSON',
        mergeStats: 'View Merge Health',
        tableHealthStats: 'View Table Health',
        id: 'ID',
        dateRange: 'Date Range',
        totalSize: 'Total Size',
        totalVolume: 'Total Volume',
        noneFound: 'none found',
      },
      sources: {
        title: 'Table sources',
        titleSummary: 'Summary table sources',
        apiStream: 'api stream',
        autoBatch: 'auto batch',
        kafka: 'kafka',
        kinesis: 'kinesis',
      },
      summaryAnalysis: {
        title: 'Summary table analysis',
        nonSummaryTitle: 'Table analysis',
        submitBtn: 'Analyze Table',
        copySql: 'Copy sql generated',
        tooltips: {
          results: 'Copied generated sql',
        },
        fields: {
          labels: {
            range: 'Range',
            rows: 'Rows',
          },
        },
        tabs: {
          results: 'Results',
        },
        tables: {
          reduction: {
            columnName: 'column_name',
            reduction: '%_reduction',
            recommendation: 'recommendation',
          },
          columnStatistics: {},
          storageFootprint: {},
        },
      },
      transform: {
        title: 'Table transforms',
        titleSummary: 'Summary table settings',
      },
      adv: {
        title: 'Advanced options',
        bucket: 'bucket settings',
        bucketTooltip: 'Customized policy-based storage routing',
        lifecycle: 'data lifecycle management',
        lifecycleTooltip: 'Custom settings to control data aging',
        index: 'index settings',
        indexTooltip: 'Custom data sorting and sharding',
        merge: 'merge settings',
        mergeTooltip: 'Custom settings for data compaction',
        partition: 'flush settings',
        partitionTooltip: 'Customized partition flushing for stream data',
        summary: 'summary settings',
        summaryTooltip: 'Customized summary tables',
        streamAuth: 'stream settings',
        streamAuthTooltip: 'Customized stream tokens',
        queryOptions: 'query options',
        queryOptionsTooltip: 'Customized table query options',
        rateLimit: 'rate limit settings',
        rateLimitTooltip: 'Customized table rate limit settings',
        rename: 'rename, truncate, or remove table',
        renameSummary: 'rename, truncate, or remove summary table',
      },
      menu: {
        query: 'Query Options',
        rateLimit: 'Rate Limit',
        rename: 'Rename',
        remove: 'Delete',
        truncate: 'Truncate',
        edit: 'Edit',
        delete: 'Delete',
        default: 'Mark as default',
      },
      delete: {
        title: 'Delete table',
        text: (tableName: string) =>
          `Deleting table ${tableName}. The table structure, resources, and data will be deleted. Type “DELETE” to confirm.`,
        success: 'Table successfully deleted',
      },
      truncate: {
        title: 'Truncate Table',
        text: (tableName: string) =>
          `Truncating table ${tableName}. Truncating a table will delete all data in that table. The table and table structure will be preserved. Type “TRUNCATE” to confirm.`,
        success: 'Table successfully truncated',
        btnSubmit: 'Truncate',
      },

      rename: {
        title: 'Table - rename or remove',
        tableNameLabel: 'Table name',
        success: 'Table successfully renamed',
        BTN: {
          delete: 'Delete table',
          submit: 'Save changes',
        },
      },
      bucketSettings: {
        title: 'Table - bucket settings',
        additionalTitle: 'Map',
        addMapBtn: 'Add mapping',
        saveBtn: 'Save changes',
        deleteBtn: 'Delete',
        successNoti: 'Bucket settings successfully changed',
        labels: {
          columnName: 'Column Name',
          defStorageId: 'Default Storage ID',
          values: 'Values',
          storageId: 'Storage ID',
        },
        labelTooltips: {
          columnName: 'Column to search for special mapping',
          defStorageId: 'Default storage location if no other is specified',
          values:
            'If these values are found in the specified column, map to the default storage location',
          storageId: 'Map to this storage location',
          additionalValues:
            'If these values are found in the specified column, map to the specified storage location',
        },
        rules: {
          columnName: 'Column Name is required',
          defStorageId: 'Default Storage ID may not be blank',
        },
      },
      queryOptions: {
        success: 'Table query options successfully changed',
      },
      partitionSettings: {
        title: 'Table - flush settings',
        submitBTN: 'Save changes',
        hotTitle: 'Hot data',
        coldTitle: 'Cold data',
        partition: 'Partitions',
        rows: 'Rows',
        sec: 'Sec',
        min: 'Min',
        day: 'Days',
        labels: {
          maxActive: 'Max Active Partitions',
          maxRows: 'Max Rows per Partition',
          maxMinutes: 'Max Minutes per Partition',
          maxOpen: 'Max Open Seconds',
          maxIdle: 'Max Idle Seconds',
          maxAgeHot: 'Max Age (Minutes)',
          maxAgeCold: 'Max Age (Days)',
        },
        labelTooltips: {
          hot: {
            maxActive: 'The maximum number of partitions to keep open at any one time',
            maxRows:
              'The maximum size (measured in number of rows) to allow any open partition to reach',
            maxMinutes: 'The maximum width in time of a partition in minutes',
            maxOpen:
              'The maximum duration in seconds to wait for events to trickle in for a recent-data partition',
            maxIdle:
              'The maximum duration in seconds to wait from the last received event before automatically closing an open partition',
            maxAge: 'Minutes from now() that data is hot',
          },
          cold: {
            maxActive: 'The maximum number of partitions to keep open at any one time',
            maxRows:
              'The maximum size (measured in number of rows) to allow any open partition to reach',
            maxMinutes: 'The maximum width in time of a partition in minutes',
            maxOpen:
              'The maximum duration in seconds to wait for events to trickle in for a recent-data partition',
            maxIdle:
              'The maximum duration in seconds to wait for new data to appear at all before automatically closing an open partition',
            maxAge: 'Days from now() plus hot data max age that data is cold',
          },
        },
        err: {
          hot: {
            maxActiveRequired: 'Hot data max active is required',
            maxRowsRequired: 'Hot data max rows is required',
            maxMinutesRequired: 'Hot data max minutes is required',
            maxOpenRequired: 'Hot data max open is required',
            maxIdleRequired: 'Hot data max idle is required',
            maxAgeRequired: 'Hot data max age is required',
          },
          cold: {
            maxActiveRequired: 'Cold data max active is required',
            maxRowsRequired: 'Cold data max rows is required',
            maxMinutesRequired: 'Cold data max minutes is required',
            maxOpenRequired: 'Cold data max open is required',
            maxIdleRequired: 'Cold data max idle is required',
            maxAgeRequired: 'Cold data max age is required',
          },
          messageQueueRows: 'Max queue rows is required',
        },
        success: 'Flush settings successfully changed',
      },
      indexSettings: {
        title: 'Table - index settings',
        submitBTN: 'Save changes',
        day: 'Days',
        labels: {
          sortKeys: 'Sort Keys',
          shardKey: 'Shard Key',
          maxFuture: 'Max Future',
        },
        labelsTooltips: {
          sortKeys: 'Comma delineated list of column names by sort priority',
          shardKey: 'Adding a shard key causes data to be sorted at time of ingest',
        },
        err: {
          sortKeysRequired: 'Sort keys may not be blank',
          shardKeyRequired: 'Shard key may not be blank',
        },
        success: 'Index settings successfully changed',
        shardKeyWarning:
          'Shard key may not be changed once it is set. Shard keys can only be updated or changed by contacting Hydrolix support.',
      },
      lifecycleSettings: {
        title: 'Table - data lifecycle management',
        submitBTN: 'Save changes',
        day: 'Days',
        labels: {
          maxAge: 'Max Age',
          delete: 'Delay Before Permanent Deletion',
        },
        labelsTooltips: {
          maxAge: 'The total number of days the data is available to be viewed from today',
          delete:
            'The total number of days data is available before being deleted once it has been marked for deletion',
          maxFuture: 'The total number of days of data that has a primary timestamp beyond now()',
        },
        success: 'Lifecycle settings successfully changed',
      },
      mergeSettings: {
        title: 'Table - merge settings',
        submitBTN: 'Save changes',
        labels: {
          disable: 'Disable Merge',
          memoryCoefficient: 'Memory Coefficient',
          poolsTitle: 'Merge Pools',
          poolsSmall: 'Small',
          poolsMedium: 'Medium',
          poolsLarge: 'Large',
        },
        validate: {
          memoryCoefficient: 'Memory Coefficient must be a positive number',
        },
        labelsTooltips: {
          disable: 'If true, deactivates merge service',
          memoryCoefficient: `multiplier used when calculating a partition's memory when building a merge candidate`,
          poolsSmall: 'Primary Timestamp < 1 Hr Ago',
          poolsMedium: 'Primary Timestamp 1-24 Hrs Ago',
          poolsLarge: 'Primary Timestamp > 1 Day Ago',
        },
        success: 'Merge settings successfully changed',
      },
      streamAuthSettings: {
        title: 'Table - stream settings',
        submitBTN: 'Save changes',
        labels: {
          tokenEnabled: 'Enable token auth',
          tokenList: 'Token List',
          maxQueueRows: 'Max Queue Rows',
        },
        labelsTooltips: {
          tokenEnabled: 'If true, enables token auth',
          tokenList: 'Comma delineated list of tokens',
          messageQueueRows: 'The maximum number of queue rows',
        },
        success: 'Stream settings successfully changed',
      },
      summarySettings: {
        title: 'Table - summary settings',
        submitBTN: 'Save changes',
        addColumn: 'Create column',
        deleteBTN: 'Delete',
        labels: {
          disable: 'Enable Summary',
          sql: 'SQL',
        },
        labelsTooltips: {
          disable: 'If true, enables summary table',
          sql: 'SQL applied to summary tables',
        },
        success: 'Summary settings successfully changed',
      },
    },
    ingest: {
      new: {
        title: 'New ingest source',
        type: 'Source type',
        tableLabel: 'Select Table',
        tabs: {
          batch: 'Auto Ingest',
          kafka: 'Kafka',
          kinesis: 'Kinesis',
          siem: 'Akamai SIEM',
        },
        tabsTooltips: {
          batch: 'Continually ingest new files',
          kafka: 'Apache Kafka',
          kinesis: 'AWS Kinesis',
          siem: 'Akamai SIEM',
        },
        common: {
          name: 'Name',
          submitBTN: 'Add source',
          transform: 'Select transform',
          scale: 'Scale',
          replicas: 'Replicas',
          cpu: 'CPU',
          memory: 'Memory',
          storage: 'Ephemeral storage',
          adv: 'Edit advanced options',
          gb: 'GB',
          mi: 'Mi',
          m: 'm',
          err: {
            transformRequired: 'Transform is required',
            nameRequired: 'Name is required',
            replicasRequired: 'Replicas is required',
            replicasMinRequired: 'Replica min is required',
            replicasMaxRequired: 'Replica max is required',
            cpuRequired: 'CPU is required',
            memoryRequired: 'Memory is required',
            storageRequired: 'Storage is required',
            max27: 'Max length - 27 symbols',
            memoMin: 'Min memory must be greater than 0',
            storageMin: 'Min storage must be greater than 0',
          },
        },
        batch: {
          sqsName: 'Queue Name',
          sqsNamePlaceholder: 'sqs://my-sqs-queue',
          filter: 'Regex filter',
          sourceCredential: 'Source Credential (optional)',
          bucketCredential: 'Bucket Credential (optional)',
          region: 'Source Region',
          advanced: {
            title: 'Auto ingest - advanced options',
            dryRunTitle: 'Dry Run Options',
            indexTitle: 'Index Options',
            labels: {
              dryRun: 'Dry Run Enabled',
              maxMin: 'Max Minutes',
              maxRows: 'Max Rows',
              maxActive: 'Max Active',
            },
            units: {
              maxMin: 'Min',
              maxRows: 'Rows',
              maxActive: 'Partitions',
            },
          },
          tooltips: {
            sqsName:
              'SQS queue name containing the S3 notifications. The name must be prefixed with sqs://',
            filter: 'The S3 event notification regex pattern',
            dryRun: 'If true, all indexing work will be done, but no results will be uploaded',
            maxMin: 'The maximum number of minutes to hold in a partition',
            maxRows: 'The max row count limit per partitions',
            maxActive: 'Maximum number of active partitions',
          },
          err: {
            sourceRequired: 'Queue name is required',
          },
          success: 'New auto batch source added',
        },
        kafka: {
          bootstrap: 'Bootstrap servers',
          topics: 'Topics',
          bootstrapRequired: 'Bootstrap servers is required',
          topicsRequired: 'Topics is required',
          success: 'New kafka source added',
          tooltips: {
            bootstrap:
              'Comma delineated list of Kafka bootstrap server addresses, in "HOST:PORT" format',
            topics: 'Comma delineated list of Kafka topics to import from the given servers',
            replicaMin: 'Min Kubernetes replicas in Kafka ingest pool',
            replicaMax: 'Max Kubernetes replicas in Kafka ingest pool',
            memory: 'Memory in Kafka ingest pool',
            storage: 'CPU in Kafka ingest pool',
            readTimeout: 'The length of time to wait for any data to appear in kafka in seconds',
            maxWait:
              'The length of time to wait for "enough" data to appear in kafka in milliseconds',
            offset:
              'Where to start reading kafka (earliest = from the beginning of time; latest = from the most recent message, ignoring everything before)',
          },
          advanced: {
            title: 'Kafka - advanced options',
            labels: {
              readTimeout: 'Read Timeout',
              maxWait: 'Max Wait',
              offset: 'Offset',
            },
            units: {
              readTimeout: 's',
              maxWait: 'ms',
            },
          },
        },
        kinesis: {
          name: 'Stream name',
          region: 'Region',
          checkpointer: 'Checkpointer ARN',
          nameRequired: 'Stream name is required',
          regionRequired: 'Region is required',
          checkpointerRequired: 'Checkpointer ARN is required',
          success: 'New kinesis source added',
          datastoreProject: 'Datastore project ID',
          advanced: {
            title: 'Kinesis - advanced options',
            subTitle: 'AWS Run Options',
            labels: {
              awsKey: 'AWS Key',
              awsSecret: 'AWS Secret',
              awsRole: 'AWS Role',
            },
            errors: {
              awsKey: 'AWS Key is required if AWS Secret is filled',
              awsSecret: 'AWS Secret is required if AWS Key is filled',
            },
          },
          tooltips: {
            streamName: 'ARN for the Kinesis stream',
            region: 'Region for the Kinesis stream',
            checkpointer: 'ARN for the DynamoDB',
            replicaMin: 'Min Kubernetes replicas in Kinesis ingest pool',
            replicaMax: 'Max Kubernetes replicas in Kinesis ingest pool',
            memory: 'Memory in Kinesis ingest pool',
            storage: 'CPU in Kinesis ingest pool',
            datastoreProject: 'Specifies a specific project in GCP.',
          },
        },
        siem: {
          tableLabelParent: 'Select Parent Table',
          tableRequired: 'Source Table is required',
          host: 'Host',
          entityId: 'Web Security Configuration ID',
          clientSecret: 'Client Secret',
          clientToken: 'Client Token',
          accessToken: 'Access Token',
          clientSecretKey: 'Client Secret Key',
          clientTokenKey: 'Client Token Key',
          accessTokenKey: 'Access Token Key',
          hostRequired: 'Host name is required',
          entityRequired: 'Web Security Configuration ID is required',
          clientSecretRequired: 'Client Secret is required',
          clientTokenRequired: 'Client Token is required',
          accessTokenRequired: 'Access Token is required',
          success: 'New Akamai SIEM source added',
          copy: {
            host: 'copy',
            clientSecretKey: 'copy',
            clientTokenKey: 'copy',
            accessTokenKey: 'copy',
          },
          tooltips: {
            replicaMin: 'Min Kubernetes replicas in Akamai SIEM ingest pool',
            replicaMax: 'Max Kubernetes replicas in Akamai SIEM ingest pool',
            memory: 'Memory in SIEM ingest pool',
            storage: 'CPU in SIEM ingest pool',
          },
        },
      },
      edit: {
        common: {
          submitBTN: 'Edit source',
        },
        kafka: {
          title: 'Edit kafka source',
          success: 'Kafka source successfully edited',
        },
        kinesis: {
          title: 'Edit kinesis source',
          success: 'Kinesis source successfully edited',
        },
        autoBatch: {
          title: 'Edit auto batch source',
          success: 'Auto batch source successfully edited',
        },
        siem: {
          title: 'Edit Akamai SIEM source',
          success: 'Akamai SIEM source successfully edited',
          keys: 'The original values for client secret, client token, and access token are encoded by the API as keys and are not displayed. ',
          accessDetails:
            'If the Web Security Configuration is updated, user must re-enter the client secret, client token, and access token.',
        },
      },
      delete: {
        title: 'Delete source',
        text: (type: string) => `Are you sure you want to delete ${type} source `,
        success: 'Source successfully deleted',
      },
    },
    transform: {
      title: 'New ingest transform',
      new: {
        success: 'New transform successfully added',
      },
      edit: {
        schema: 'Transform Output Columns',
        sql: 'Transform SQL',
        data: 'Sample data',
        output: 'Output',
        stats: 'Transform stats',
        outputTableEmpty: 'Validate transform to see the query response table',
        success: 'Transform successfully modified',
        viewTabs: {
          json: 'JSON',
          column: 'Column',
        },
        toolTips: {
          schema: 'output columns list',
          sql: 'sql to apply to transform',
          data: 'sample data to validate against',
        },
        btns: {
          validate: 'Validate transform',
          options: 'Format options',
          settings: 'Transform settings',
          publish: 'Publish transform',
          download: 'Download transform',
          delete: 'Delete transform',
          forceOperation: 'Force Operation',
        },
        forceOperationModal: {
          title: 'Force Operation',
          description: 'You have force_operation set to true. Type "FORCE" to confirm:',
          submitBtn: 'Force',
          form: {
            required: 'This field is required.',
            invalidInput: 'Incorrect input',
          },
        },
        form: {
          csv: {
            title: 'CSV format options',
            delimiter: 'Delimiter',
            quote: 'Quote',
            comment: 'Comment',
            escape: 'Escape',
            skipHead: 'Skip Head',
            skipComments: 'Skip comments',
            winEnd: 'Windows Endings',
          },
          csvTooltips: {
            delimiter: 'The delimiter substring',
            quote: 'The quote character',
            comment: 'The comment character. Only single characters are supported',
            escape: 'The escape character',
            skipHead: 'The number of rows to skip before ingestion starts',
            skipComments:
              'If true, then ingest will not process lines beginning with the comment character',
            winEnd:
              'If true, then Hydrolix will expect incoming data to use Windows-style (CR-LF) line endings',
          },
          json: {
            title: 'JSON format options',
            flatteningTitle: 'Flattening',
            active: 'Is active',
            depth: 'Depth',
            mapTitle: 'Map flattening strategy',
            sliceTitle: 'Slice flattening strategy',
            left: 'Left',
            right: 'Right',
            advOptionsTitle: 'Advanced Options',
            subtype: 'Subtype',
          },
          jsonTooltips: {
            active:
              'If true, Hydrolix will flatten incoming JSON objects before ingesting them as rows',
            subtype:
              'Adds support for decoding/decompressing json data that comes from the specified service',
            depth: 'The depth which flattening is enabled, 0 means everything',
            mapTitle: `Configuration for flattening any JSON objects within each row's main object`,
            sliceTitle: `Configuration for flattening any JSON arrays within each row's main object`,
          },
          settings: {
            title: 'Transform Settings',
            default: 'Is Default',
            null: 'Null Values',
            compression: 'Compression',
            rateLimit: {
              title: 'Rate Limit Settings',
              limitLabel: 'Limit',
              burstLabel: 'Burst',
              rightText: 'Bytes',
              validateErrors: {
                limit: 'Limit value must be greater than 0',
                burst: 'Burst value must be greater than 0',
                required: 'This field is required',
              },
            },
          },
          settingsTooltips: {
            default: `If true, then this transform's table will apply this transform to a streaming ingest`,
            null: 'Comma separated list of strings which will be replaced by null if found in the value',
            compression: 'Comma separated list of the compression applied to the data',
          },
        },
        err: {
          dataInvalid: 'Sample data must be in valid JSON format',
          dataCSVInvalid: 'Sample data must be in valid CSV format',
          schemaJSONInvalid: 'Schema definition is not valid JSON',
          genericPublishError: 'Error publishing transform',
          typeJsonSubmitCSV: 'Transform type is json, but sample data is not valid JSON',
          mismatch: {
            json: 'Mismatch: Transform type is csv and sample data is json',
            csv: 'Mismatch: Transform type is json and sample data is csv',
          },
          incorrectFormat: {
            json: 'Transform type is json, but sample data is not valid JSON',
            csv: 'Transform type is CSV, but sample data is not valid CSV',
          },
        },
        columnView: {
          tableOptions: {
            add: 'Create',
            edit: 'Edit',
            delete: 'Delete',
          },
          tableColumnName: 'Column',
          tableTypeName: 'Type',
          columnCreate: 'Create new column',
          columnSidebar: {
            title: 'Column Settings',
            fields: {
              name: 'Name',
              type: 'Type',
              format: 'Format',
              resolution: 'Resolution',
              primary: 'Primary',
              ignore: 'Ignore',
              index: 'Index',
              fulltext: 'Full Text Search',
              schemaDefinition: 'Schema Definition',
            },
          },
          validation: {
            formatRequired: 'Format is required if type is datetime or epoch',
            name: 'name is required and must be non-empty',
            type: 'type is required and must be non-empty',
            elements: 'elements can exist if the type is array or map',
            primary: 'primary can be set to true if the type is datetime or epoch',
            fulltext: 'index must be enabled before enabling fulltext search',
            format: 'format is only required if type is datetime or epoch',
            resolution: 'resolution must be "ms" (milliseconds) or "s" (seconds)',
            ignore: "primary column can't be ignored",
            index: "index can't be enabled if the type is double",
          },
          infoText:
            'Limited datatype attributes shown in this form. Edit the above field to add additional attributes or edit the JSON view. See documentation for more advanced attributes',
        },
      },
      source: {
        title: 'Source',
        tabs: {
          akamai: 'Akamai',
          fastly: 'Fastly',
          edgecast: 'Edgecast',
          aws: 'AWS',
          prometheus: 'Prometheus',
        },
        errors: {
          downloadFailed: 'Error during the download of the transform.',
          fileIsNotValid: 'The downloaded transform is invalid',
          uploadNonJson: 'The uploaded data is a non-json file',
        },
      },
      type: {
        title: 'Data type',
        tabs: {
          csv: 'CSV',
          hdx: 'HDX',
          json: 'JSON',
        },
      },
      method: {
        title: 'Method',
        tabs: {
          clone: {
            title: 'Clone',
            toolTip: 'clone an existing transform',
          },
          edit: {
            title: 'Create',
            toolTip: 'create a new transform',
          },
          generate: {
            title: 'Generate',
            toolTip: 'generate a transform from sample data',
          },
          upload: {
            title: 'Upload',
            toolTip: 'upload a transform file',
          },
          download: {
            title: 'Download',
            toolTip: 'download a transform file',
          },
        },
      },
      form: {
        table: 'Select table',
        name: 'Transform name',
        description: 'Description',
        clone: 'Clone',
        upload: 'Upload transform',
        err: {
          tableRequired: 'Table is required',
          nameRequired: 'Name is required',
          namePattern:
            'Name cannot begin with a numeric or special character and can only contain alphanumeric characters and underscores.',
          maxLength: 'Name must have no more than 64 characters',
        },
      },
      clone: {
        cloneLabel: 'Clone',
      },
      generate: {
        descLabel: 'Sample data',
        descRequired: 'Please provide CSV example with headers',
        delimiter: 'Delimiter',
      },
      upload: {
        fileLabel: 'Upload transform',
        browseBTN: 'Browse files',
        fileRequired: 'Please select file',
      },
      download: {
        label: 'Download',
      },
      addBTN: 'Add transform',
      editBTN: 'Validate transform',
      uploadBTN: 'Upload transform',
      generateBTN: 'Generate transform',
      addSuccess: 'New transform added',
      delete: {
        title: 'Delete transform',
        text: (name: string) => `Are you sure you want to delete transform ${name}?`,
        success: 'Transform successfully deleted',
      },
    },
    view: {
      newTitle: 'New view',
      editTitle: 'Edit view',
      tableViewsTitle: 'Table Views',
      delete: {
        deleteTitle: 'Delete view',
        text: (name: string) => `Are you sure you want to delete view ${name}?`,
        success: 'View successfully deleted',
      },
      form: {
        selectTable: 'Select table',
        viewName: 'View Name',
        description: 'Description',
        parentView: 'Parent View',
        submitButton: 'Create view',
        editButton: 'Edit view',
        deleteButton: 'Delete view',
        errors: {
          selectTable: {
            required: 'Please, select the table',
          },
          viewName: {
            required: 'View name is required',
            max: 'View name must be less than 64 symbols',
          },
          parentView: {
            required: 'Parent view is required',
          },
          outputColumns: {
            empty: 'No columns selected',
            noAutoView: 'This table does not contain auto_view',
          },
        },
        successCreatedNoti: 'View successfully created',
        successEditedNoti: 'View successfully edited',
      },
      tables: {
        currentTitle: 'Current View',
        autoViewTitle: 'Auto View',
        column: 'Column',
        type: 'Type',
        menu: {
          details: 'Details',
          delete: 'Delete',
        },
      },
    },
  },

  scale: {
    title: 'Pools',

    tabs: {
      ingest: 'Scale ingest',
      search: 'Scale search',
      core: 'Scale core',
    },

    tableIngest: {
      service: 'Service',
      pool: 'Pool',
      replica: 'Replicas',
    },

    new: {
      title: 'New resource pool',
      editTitle: 'Edit pool',
      serviceLabel: 'Service',
      tableLabel: 'Table',
      typeLabel: 'Type',
      transformLabel: 'Destination transform',
      replicaMinLabel: 'Replica min',
      replicaMaxLabel: 'Replica max',
      nameLabel: 'Name',
      replicaLabel: 'Replicas',
      memoryLabel: 'Memory',
      storageLabel: 'Ephemeral storage',
      success: 'Pool successfully created',
      successUpdated: 'Pool successfully updated',
      noteText:
        'Resource pool creation and management is limited in UI. Use the Hydrolix Config Api or Kubernetes for more advanced pool scaling and management.',
      submitBTN: 'Create pool',
      updateBTN: 'Publish update',
      deleteBTN: 'Delete pool',

      advanced: {
        title: 'Pool - advanced options',
        isDefault: 'Default',
        shards: 'Shards',
        cpu: 'CPU',
        cpuLabel: 'CPU',
        description: 'Description',
      },

      err: {
        service: { required: 'Please, select the service' },
        type: { required: 'Please, select the type' },
        table: { required: 'Please, select the table' },
        transform: { require: 'Transform is required' },
        replicaMin: {
          required: 'Min replicas required if max replica is present',
          minThenMax: 'Min replicas should be less then max replicas',
        },
        replicaMax: {
          required: 'Max replicas required if min replica is present',
          maxThenMin: 'Max replicas should be greater then min replicas',
        },
        name: {
          required: 'Name is required',
          max: 'Name must be less than 64 symbols',
        },
      },
    },

    delete: {
      title: 'Delete pool',
      content: 'Are you sure you want to delete ',
      cancelBTN: 'Cancel',
      submitBTN: 'Delete',
      successMsg: 'Pool successfully deleted',
    },
  },

  // JOBS

  jobs: {
    tabs: {
      alter: 'Alter jobs',
      batch: 'Batch jobs',
    },
    cancelJobModalText: 'Are you sure you want to cancel ',
    closeBtn: 'Close',
    cancelJobBtn: 'Cancel Job',
    purgeJobs: 'Purge jobs',
    purgeTooltip: 'Purge all failed, canceled, unknown, and done jobs',
    purgeModalText: 'Are you sure you want to purge all failed, canceled, unknown, and done jobs?',
    purgeSuccess: 'Jobs successfully purged',
    alterJobs: {
      new: {
        title: 'New alter job',
        alterSQL: 'Alter job SQL',
        submitBTN: 'Start job',
        success: 'Alter job successfully created',
        required: 'Alter job SQL required',
        alterSQLTooltip: 'SQL to update and delete specific rows',
      },
      table: 'Table',
      sql: 'SQL statement',
      timestamp: 'Timestamp',
      status: 'Status',
      cancelJobModalTitle: 'Cancel alter job',
      edit: {
        title: 'Edit alter job',
        submitBTN: 'Save changes',
        deleteBTN: 'Delete alter job',
        success: 'Alter job successfully modified',
      },
      delete: {
        title: 'Delete alter job',
        content: 'Are you sure you want to delete ',
        cancelBTN: 'Cancel',
        submitBTN: 'Delete',
        commitBTN: 'Commit changes',
        success: 'Alter job successfully deleted',
      },
      options: {
        edit: 'View',
        cancel: 'Cancel',
        retry: 'Retry',
        delete: 'Delete',
        commit: 'Commit changes',
        success: {
          cancel: 'Alter job successfully canceled',
          commit: 'Alter job successfully committed',
          retry: 'Alter job successfully retried',
        },
      },

      editSidebar: {
        title: 'View alter job',
        tableLabel: 'Table',
        sql: 'SQL Statement',
        timestampLabel: 'Timestamp',
        statusLabel: 'Status',
      },
    },

    batchJobs: {
      table: 'Table',
      name: 'Name',
      timestamp: 'Timestamp',
      status: 'Status',
      cancelJobModalTitle: 'Cancel batch job',
      edit: {
        title: 'Edit batch job',
        submitBTN: 'Save changes',
        deleteBTN: 'Delete batch job',
        success: 'Batch job successfully modified',
      },
      options: {
        edit: 'View',
        cancel: 'Cancel',
        retry: 'Retry',
        delete: 'Delete',
        success: {
          cancel: 'Batch job successfully canceled',
          retry: 'Batch job successfully retried',
        },
      },
      delete: {
        title: 'Delete batch job',
        content: 'Are you sure you want to delete ',
        cancelBTN: 'Cancel',
        submitBTN: 'Delete',
        success: 'Batch job successfully deleted',
      },
      new: {
        title: 'New Batch job',
        editTitle: 'Edit batch job',
        nameLabel: 'Name',
        descriptionLabel: 'Description',
        credential: 'Credential (optional)',
        tableLabel: 'Destination table',
        bucketName: 'Bucket Name',
        cloud: 'Select cloud provider',
        region: 'Region',
        bucketPath: 'Bucket Path',
        endpoint: 'Endpoint',
        transformLabel: 'Destination transform',
        poolLabel: 'Batch pool',
        sqlLabel: 'Alter job SQL',
        updateBTN: 'Edit job',
        submitBTN: 'Start job',
        success: 'Batch job successfully created',
        tooltips: {
          urlLabel: 'URL to the data location',
          tableLabel: 'Table that will hold the data',
          transformLabel: 'Transform to apply to the data',
        },
        advanced: {
          title: 'Batch job - advanced options',
          dryRun: 'Dry Run Options',
          options: 'Options',
          dryRunLabel: 'Dry Run Enabled',
          maxFilesLabel: 'Dry Run Max Files',
          maxMinutesLabel: 'Max Minutes',
          maxRowsLabel: 'Max Rows',
          maxActiveLabel: 'Max Active',
          inputAggregationLabel: 'Input Aggregation',
          inputConcurrencyLabel: 'Input Concurrency',
          regexFilterLabel: 'Regex Filter',
          units: {
            files: 'Files',
            min: 'Min',
            partitions: 'Partitions',
            rows: 'Rows',
            bytes: 'Bytes',
          },

          tooltips: {
            dryRunLabel: 'If true, all indexing work will be done, but no results will be uploaded',
            maxFilesLabel: 'Number of files to dispatch to peers',
            maxMinutesLabel: 'Maximum number of minutes to hold in a partition',
            maxActiveLabel: 'Maximum number of active partitions',
            maxRowsLabel: 'Data size of the partition',
            inputAggregationLabel: 'Size of a single unit of work',
            regexFilterLabel: 'Filters the files to ingest using a Regex match',
          },
        },

        validate: {
          minNumber: 'min value',
          url: 'Url is required',
          table: 'Table is required',
          transform: 'Transform is required',
          bucketName: 'Bucket name is required',
          cloud: 'Cloud is required',
          region: 'Region is required',
          endpoint: 'Endpoint is required',
          bucketPath: 'Bucket path is required',
        },
      },

      editSidebar: {
        credential: 'Credential',
        dryRun: 'Dry run',
        error: 'Error',
        na: 'n/a',
        nameLabel: 'Name',
        source: 'Source',
        sourceUrlLabel: 'Source URL',
        statusLabel: 'Status',
        tableLabel: 'Source Table',
        timestampLabel: 'Timestamp',
        title: 'View batch job',
      },
    },
  },

  // QUERY
  query: {
    tabs: {
      editor: 'Query editor',
      reference: 'Query reference',
      result: 'Query results',
      statistics: 'Query statistics',
      analysis: 'Query analysis',
      hierarchy: 'Query Options Hierarchy',
    },
    hierarchyTableQueryOption: 'Query Option',
    hierarchyTableResult: 'Result',
    resultTab: {
      openList: (count: number | string) => `Displaying all rows (${count} rows returned)`,
      closeList: 'Hidden rows',
    },
    runButton: 'Run query',
    copyEditorValue: 'Copy editor value',
    copyTableName: 'Copy table name',
    largeResultWarning:
      'UI is rate limited to 10,000 max rows. For result sets anticipated to be larger than 10,000 rows use a different query interface.',
    options: {
      button: 'Query options',
      formLabel: 'Query options',
      resetBTN: 'Reset',
      saveBTN: 'Save changes',

      outputFormatting: {
        title: 'Output Formatting',
        outputFileEnabled: 'Output file enabled',
        queryFileName: 'Output to file',
        outputFormat: 'Output format',
        queryComment: 'Comment',
        queryAdminComment: 'Admin comment',
        queryDebug: 'Query Debug Enabled',
        queryLabel: 'Query Label',
      },
      circuitBreakers: {
        title: 'Circuit Breakers',
        queryMaxRows: 'Max rows',
        queryMaxAttempts: 'Max attempts',
        maxResultBytes: 'Max result bytes',
        maxResultRows: 'Max result rows',
        queryMaxTimeRange: 'Max timerange',
        timerangeRequired: 'Timerange required',
        queryMaxPartitions: 'Max partitions',
        maxExecutionTime: 'Max execution time',
        maxColumnsToRead: 'Max columns to read',
        queryMemoryUsage: 'Max memory usage',
        queryMaxRowsError: 'UI is limited to 10,000 max rows.',
        catalogTimeoutMs: 'Max Catalog Timeout',
        catalogTimeoutMsUnits: 'ms',
        unlimitedCnf: 'Unlimited cnf',
      },
      rateLimiting: {
        title: 'Rate Limiting',
        queryMaxPeers: 'Max peers',
        queryPoolName: 'Query peer pool',
        queryMaxStreams: 'Max streams',
        maxConcurrentPartitions: 'Max concurrent partitions',
      },

      otherFlags: {
        title: 'Other Flags',
        aggregationMemoryEfficient: 'Distributed aggregation memory efficient',
        maxBytesGroupBy: 'Max bytes before external group by',
        maxBytesExternalSort: 'Max bytes before external sort',
        alterReliableUpdateEnabled: 'Alter reliable update enabled',
      },

      queryMaxExecutionTime: 'Max timeout',
      queryMaxExecutionTimeRightText: 'Seconds',

      tooltips: {
        timerangeRequired: `If true, will not allow a query to run if there's no time range specified in the WHERE clause`,
        maxResultRows:
          'The maximum number of rows that can be stored as a result of a query on the Query Head before returning the response',
        maxResultBytes:
          'The maximum amount of bytes that can be stored on the Query Head before returning data',
        queryMaxAttempts: 'Specifies the maximum number of failures that can occur',
        queryMaxRows:
          'Specifies the maximum number of rows that can be evaluated to answer a query',
        queryAdminComment: 'Add an admin comment to the query which is stored in active query',
        outputFileEnabled: 'If true, will save the query result to a file on your cloud storage',

        queryPoolName: 'Run the query only in peers belonging to the pool chosen',
        queryComment: 'Add a comment to the query which is stored in active query',
        queryFileName:
          'Indicates whether you want to save a query result to a file on your cloud storage',
        queryMaxPeers:
          'Setting this flag instructs the query head to instead use only a subset of available peers',
        queryMaxStreams: 'Limit the number of processes a query might run',
        queryMaxPartitions: 'Number of partition a query is allowed to access',

        maxExecutionTime: 'Number of seconds before a query is canceled',
        maxColumnsToRead:
          'Number of columns allowed in SELECT statement before a query is canceled',
        maxConcurrentPartitions:
          'Max number of partitions which a query peer reads from at the same time',
        aggregationMemoryEfficient:
          'Relates to https://github.com/ClickHouse/ClickHouse/pull/20599',
        maxBytesGroupBy:
          'Max amount of bytes that can be used in memory before data is spilt to disk when applying group bys',
        maxBytesExternalSort:
          'Max amount of bytes that can be used in memory before data is spilt to disk when applying sort bys',
        queryMaxTimeRange: 'Specify the maximum timerange allowed in the query in seconds',
        queryMemoryUsage: 'Max memory in bytes for a single query',
        queryDebug:
          'Hydrolix will respond with an HTTP Header X-Hdx-Query-Stats with performance detail',
        unlimitedCnf:
          'Disables limits on the number of clauses when converting the query to conjunctive normal form (CNF). See the ClickHouse documentation for more information.',
        outputFormat: 'output format recognized by Clickhouse engine.',
      },
      reset: {
        modalTitle: 'Reset query options',
        modalContent: 'Are you sure you want to reset query options',
        confirmBTN: 'Confirm',
        cancelBTN: 'Cancel',
      },
    },
    downloadJSON: 'Download JSON',
    downloadCSV: 'Download CSV',
    emptyQueryResultText: ' Nothing was found for the current query',
  },

  // COMMON
  common: {
    noData: 'No Data',
    selectTable: 'Select table',
    error: 'Something went wrong',
    errorUploadFile: 'Error while file uploading',
    monacoEditorLabel: 'Submitting the query with Cmd-Enter or Ctrl-Enter',
    searchPlaceholder: 'Search',
    tableMenuOptions: {
      edit: 'Edit',
      delete: 'Delete',
    },
    buttons: {
      delete: 'Delete',
      cancel: 'Cancel',
      replace: 'Replace',
    },
    dropDown: {
      earliest: 'earliest',
      latest: 'latest',
    },
    paginationRows: 'Rows per page',
    addNew: '+ Add new',
    dropDownPlaceholder: 'Select...',
    dropDownCreatablePlaceholder: 'Type and press a space or comma...',
    deleteModal: {
      confirmationError: {
        required: 'This field is required.',
        invalidInput: 'Incorrect input',
      },
    },
  },

  docsSection: {
    formatOptionsDocs: 'Format Options Docs',
    sortKey: 'Sort Key Docs',
    shardKey: 'Shard Key Docs',
    maxAgeAndDeleteAfter: 'Max Age and Delete After Docs',
    maxFuture: 'Max Future Docs',
    mergeSettings: 'Merge Settings Docs',
    summarySettings: 'Summary Settings Docs',
    batchJobSettings: 'Batch Job Settings Docs',
    autoIngestSettings: 'Auto Ingest Settings Docs',
    autoMerge: 'Auto-merge Docs',
    maxAge: 'Max Age Docs',
    streamAuthSettings: 'Stream Auth Docs',
    streamIngest: 'Stream ingest Docs',
    transformSettings: 'Transform Settings Docs',
    regexFilter: 'Regex Filter Docs',
    outputFormatting: 'Output Formatting Docs',
    circuitBreakers: 'Circuit Breakers Docs',
    rateLimiting: 'Rate Limiting Docs',
    otherFlags: 'Other Flags Docs',
    newKafkaSettingsDocs: 'Kafka Docs',
    newKafkaSettingsApi: 'Kafka API',
    kinesisDocs: 'Kinesis Docs',
    kinesisApi: 'Kinesis API',
    policiesDocs: 'Policies Docs',
    transformSingleColumn: 'Output Column Docs',
    bucketStorageMap: 'Storage Map Docs',
  },

  // SYSTEM HEALTH
  systemHealth: {
    pageTitle: 'System Health',
    tabs: {
      counts: 'Counts',
      logs: 'Logs',
    },
    warnings: {
      logs: 'Log data is recorded in UTC format',
      counts: 'Custom ranges are limited to 6 hours and aggregated to 10 minute intervals',
    },
    fields: {
      labels: {
        service: 'Service',
        logLevel: 'Log Level',
        timerange: 'Timerange',
        pool: 'Pool',
        component: 'Component',
        container: 'Container',
        search: 'Search (logs)',
      },
      tooltips: {
        service: 'Group of hydro.logs app columns relating to a specific feature or process',
        logLevel: 'Log level options',
        timerange: 'Timerange to query. Custom Ranges are limited to 6 hours',
        pool: 'Service pools',
        component: 'Pool components. Equivalent to k8s pods',
        container: 'Component containers. Equivalent to k8s pod containers',
        search: 'String input. Used to search logs for matches',
      },
    },
    buttons: {
      submit: 'Retrieve Log Details',
      copySqlGenerated: 'Copy sql generated',
      copySqlTooltipCounts: 'Copied counts sql',
      copySqlTooltipLogs: 'Copied logs sql',
      refreshCount: 'Refresh Count',
      downloadJson: 'Download JSON',
    },
  },
};
